/* Loader.css */

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    position: absolute;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.719);
    overflow: hidden;
  }
  
 .lds-default {
    display: inline-block;
    position: relative;
    width: 50px; /* Adjust the width as needed */
    height:50px; /* Adjust the width as needed */
  }
  
  .lds-default div {
    position: absolute;
    width: 7%; /* Adjust the size of the dots as needed */
    height: 7%; /* Adjust the size of the dots as needed */
    background: rgb(127, 135, 138);
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  
  /* Adjust the top and left positions as needed */
  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37%;
    left: 66%;
  }
  .lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22%;
    left: 62%;
  }
  .lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11%;
    left: 52%;
  }
  .lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7%;
    left: 37%;
  }
  .lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11%;
    left: 22%;
  }
  .lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22%;
    left: 11%;
  }
  .lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37%;
    left: 7%;
  }
  .lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52%;
    left: 11%;
  }
  .lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62%;
    left: 22%;
  }
  .lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66%;
    left: 37%;
  }
  .lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62%;
    left: 52%;
  }
  .lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52%;
    left: 62%;
  }
  
  @keyframes lds-default {
    0%, 20%, 80%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
  
  
  