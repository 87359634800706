/* Payment.css */


.main-container {
  width: 100%;
}

.footer {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 10vh;
  padding: 0em 8vw;
  border-top: 1px solid rgb(209, 209, 209);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
}

.input-logo {
  width: 35px;
  /* Set the width of your logo */
  margin-right: 5px;
  /* Adjust the spacing between the logo and the input box */
}

.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 14vh;
}

.payment-block {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px;
  text-align: left;
  width: 60%;
}

.input-userid {
  border: none;
  padding: 0.8em 0.5em;
  border-radius: 0.2em;
  outline: none;
  width: 90%;
}

.input-userid:focus {}

.userid-btn {
  padding: 0.8em 3em;
  background-color: var(--accent-color);
  border: none;
  color: #fff;
  border-radius: 0.1em;
  margin-block: 1em;
}

.userid-verified {
  background-color: rgb(221, 221, 221);
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  line-height: 0.4em;
}

.userid-verified .userid{
  font-size: 12px;
}
.userid-verified-details{
  padding-inline: 0.5em;
  width: 90%;
}

.payment-methods {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.payment-method {
  /* background-color: red; */
  border: 1px solid #ccc;
  /* padding: 10px; */
  margin: 5px;
  cursor: pointer;
  width: 20%;
  /* min-width: 130px; */
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.payment-method input {
  position: absolute;
  opacity: 0;
}

.payment-method.selected {
  border: 2px solid var(--accent-color);
}


.method-logo {
  width: 75px;
  margin-right: 5px;
}

.payment-method-label {
  font-size: 12px;
  margin-top: 0.5em;
  /* margin-block: 0.5em; */
  text-align: center;
}

.coins-logo {
  width: 25px;
  margin-right: 5px;
}

.item-block {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px;
  text-align: left;
  max-width: 90%;
  /* Set a maximum width for the blocks */
  min-width: 80%;
  /* Set a maximum width for the blocks */
}

.items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

}

.item {
  border: 1px solid #ccc;
  padding: 10px 10px 10px 10px;
  margin: 5px;
  cursor: pointer;
  width: 26%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.item.selected {
  border: 2px solid var(--accent-color);
}

.item input {
  position: absolute;
  opacity: 0;
}

.item-labels {
  display: flex;
  flex-direction: column;
}

.item-label-amt {
  color: var(--accent-color);
  font-weight: 600;
}

.item-label-coins {
  /* color: rgb(0, 81, 255); */
  /* font-weight: 600; */
  font-size: 14px;
  line-height: 1.8em;
}

.tabs-container {
  background-color: rgb(187, 187, 187);
  display: flex;
  width: 20vw;
  padding: 0.1em;
  border-radius: 0.1em;
  margin-bottom: 1em;
}

.tab {
  width: 50%;
  text-align: center;
  background-color: rgb(187, 187, 187);
  margin: 0.1em;
  padding: 0.2em 0.3em;
  cursor: pointer;
  border-radius: 0.1em;
}

.tab.active {
  background-color: #fff;
  border-bottom: 1px solid #fff;
  /* Optional: Hide border between tab content and active tab */
}

.totalAmount {
  font-size: 25px;
  font-weight: 600;
  color: var(--accent-color);
}

.paynow-btn {
  padding: 0.8em 3em;
  background-color: var(--accent-color);
  border: none;
  color: #fff;
  border-radius: 0.1em;
  margin-block: 1em;
}

.error-message-container{
  width: 60%;
  align-items: center;
  display: flex;
  /* background-color: black; */
}

.error-message {
  color: red;
  font-size: 12px;
}

.disabled-btn {
  background-color: #0077ff70;
}

.cross-btn{
  position: relative;
  right: 0;
  top:0;
  border: none;
  border-radius: 50%;
  font-size: 10px;
  padding: 0.3em 0.5em;
  color:  var(--accent-color);
  /* background-color: red; */
}

@media only screen and (max-width: 850px) {
  .payment-block {
    /* border: 1px solid #ccc;
      padding: 20px;
      margin: 2vw;
      text-align: left; */
    width: 90%;
  }
  .error-message-container{
    width: 90%;
  }

  .item {
    width: 46%;
  }

  .payment-method {

    width: 46%;
  }

  .tabs-container {

    width: 70%;

  }
}

@media only screen and (max-width: 340px) {
  .payment-block {
    /* border: 1px solid #ccc;
      padding: 20px;
      margin: 2vw;
      text-align: left; */
    width: 90%;
  }

  .item {
    width: 90%;
  }

  .payment-method {
    width: 90%;
  }

  .tabs-container {
    width: 95%;
  }

  .footer {
    font-size: 0.8em;
  }

  .paynow-btn {
    padding: 0.8em 1.5em;
    background-color: var(--accent-color);
    border: none;
    color: #fff;
    border-radius: 0.1em;
    margin-block: 1em;
  }

  .totalAmount {
    font-size: 20px;
    font-weight: 600;
    color: var(--accent-color);
  }
}

[dir="rtl"] *{
  text-align: right;
}