/* homepage.css */
* {
    box-sizing: border-box;
}

.homepage-main-container {
    padding-inline: 20%;
    height: 100%;
    /* background-color: rgb(238, 237, 237); */
}

/* .banner-image {
    objectFit: 'cover';
    height: '100%';
    width: '100%'
} */

.banner {
    height: 200px;
}

.card-list-container {
    display: flex;
    flex-direction: column;
    /* background-color: green; */
}

.card-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    align-items: flex-start;
    /* background-color: red; */
}

.card {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fff;
}

.card-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 15px;
    margin-inline: 1em;
}

.card-content {
    /* margin-top: 10px; */
    font-size: 14px;
    display: flex;
    padding-right: 5px;

}

.card-description {
    color: rgb(49, 49, 49);
}

.card h3 {
    margin: 0;
    margin-bottom: -6px;
    padding-bottom: 0;
}

.recharge-btn {
    background-color: var(--accent-color);
    color: white;
    padding: 10px 12px;
    border: none;
    cursor: pointer;
    border-radius: 0.2em;
}

.disabled-btn {
    background-color: #0077ffa9;
  }

@media only screen and (max-width: 850px) {
    .homepage-main-container {
        padding-inline: 5%;
        /* background-color: red; */
    }

    .card-image {
        width: 70px;
        height: 70px;
        margin-right: 0.5em;
    }

    .card-content {
        /* margin-top: 10px; */
        font-size: 12px;
        display: flex;
    }

    .banner {
        height: 170px;
    }
}

@media only screen and (max-width: 350px) {
    .homepage-main-container {
        padding-inline: 3%;
        /* background-color: red; */
    }
}

@media only screen and (max-width: 300px) {
    h1 {
        font-size: 22px;
    }

    .homepage-main-container {
        padding-inline: 2%;
        /* background-color: red; */
    }

    .card {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 8px 5px;
        margin-bottom: 1em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .card button {
        background-color: var(--accent-color);
        color: white;
        padding: 7px 8px;
        border: none;
        cursor: pointer;
        font-size: 12px;
        border-radius: 0.2em;
    }

    .card-content {
        /* margin-top: 10px; */
        font-size: 11px;
        display: flex;
        padding-right: 1px;

    }


}

[dir="rtl"] * {
    text-align: right;
}