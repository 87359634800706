.header-icons{

}

.header {
    /* background-color: red; */
    height: 10vh;
    padding: 0em 8vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
.lang-btn{
  text-transform: uppercase;
  padding: 0.5em;
  border: 1px solid rgb(211, 211, 211);
  /* background-color: rgb(65, 36, 226); */
  border-radius: 0.15em;
  font-weight: 500;
}