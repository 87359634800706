*{
  box-sizing: border-box;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.disabled-btn {
  background-color: #0077ff70;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-link {
  color: #61dafb;
}

.page-logo img{
  width: 80px;
}

.app-container {
  height: 100vh;
}
.app-body-container {
  height: 90vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 340px) {
  .page-logo img{
    width: 60px;
  }
}

