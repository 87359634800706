.language-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .language-modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    position: relative;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 25px;
    cursor: pointer;
  }

  .language-modal button {
    /* display: flex; */
    /* float: right; */
    margin-top: 1.6em;
    border: none;
    padding: 0.6em 2em;
    border-radius: 0.1em;
    color: #fff;
    background-color: var(--accent-color);
  }
  .language-options {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .language-option {
    border: 1px solid #ccc;
    margin: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  
  .language-option input {
    position: absolute;
    opacity: 0;
  }
  
  .language-option.selected {
    border: 2px solid var(--accent-color); /* Replace with your accent color */
  }
  
  /* Additional styles for language option labels if needed */
  .language-option span {
    margin-left: 5px;
  }
  