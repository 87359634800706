.main-otp-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.otp-container {
  /* background-color: red; */
  /* border: 1px solid rgb(216, 216, 216); */
  min-width: 30%;
  height: 50%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

.otp-input-container input{
  width: 40px;
  margin-inline: 0.3em;
  text-align: center;
  padding: 0.7em;
  outline: none;
  font-weight: 500;
  border: 1px solid rgb(199, 199, 199);
  background-color: rgb(225, 235, 255);

}
.otp-input-container input:active{
  border: 2px solid blue;
}
.otp-input-container{
  /* margin-bottom: 1.4em; */
}
.submit-btn{
  margin-block: 1.2em;
  border: none;
  background-color: #309b47;
  color: #fff;
  width: 55%;
  padding: 0.8em 2em;
  border-radius: 1em;
}

.otp-container h4{
  margin-bottom: 0;
}
.otp-container p {
  font-size: 12px;
}
.otp-container .error-message{
  margin-top: 1em;
}
@media only screen and (max-width: 850px) {
  .otp-container {
    width: 95%;
  }

  .otp-input-container input{
    width: 35px;
    margin-inline: 0.15em;
  }
}