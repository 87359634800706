.msisdn-container {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: auto;
    padding: 20px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    justify-content: center;
    align-items: center;
    text-align: center;
  }
.msisdn-container p{
    font-size: 14px;
    line-height: 1.4em;
    text-align: center;
  }
  .msisdn-container  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 15px;
    outline: none;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 1em;
    background-color: rgb(225, 235, 255);
  }
  
  .msisdn-container button {
    background-color: #309b47;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 2em;
    width: 100%;
    cursor: pointer;
    margin-top: 1em;
    font-weight: 600;
  }
  .msisdn-container h3 {
    margin-bottom: 0px;
  }
  .msisdn-container p:nth-of-type(2) {
    /* Your styles for the first <p> tag go here */
    font-size: 12px;
    color: rgb(85, 85, 85);
    /* margin-bottom: 0px; */
  }
  .msisdn-container h3{
    /* line-height: 0.01em; */
  }

  .error-message {
    color: red;
    font-size: 12px;
    margin: 0;
  }